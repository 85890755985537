.left-place {
  position: absolute;
  top: 4%;

  z-index: 1;
}
/* FAQ BG */
.question-bg {
  background-image: url(../images/faq-background.svg);
  background-size: auto;
  background-repeat: no-repeat;
  background-position: fixed;
}

.grey-shape-bg {
  background: url("../images/home-shape.png") left center;
  height: auto;
}

.img-left-side {
  position: absolute;
  margin-left: -12%;
  transform: rotate(0deg);
}

.z-index-1 {
  z-index: 1;
}

.avatar.avatar-ex-md {
  height: 69px;
}
