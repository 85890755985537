
#preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(45deg, #ffffff, #ffffff);
    z-index: 9999999;
  }
  
  #preloader #status {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }
  
  #preloader #status .spinner {
    width: 40px;
    height: 40px;
    position: relative;
    margin: 100px auto;
  }
  
  #preloader #status .spinner .double-bounce1, #preloader #status .spinner .double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #2f55d4;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
            animation: sk-bounce 2.0s infinite ease-in-out;
  }
  
  #preloader #status .spinner .double-bounce2 {
    -webkit-animation-delay: -1.0s;
            animation-delay: -1.0s;
  }